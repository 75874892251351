<template>
    <b-card
        :img-src="require('@/assets/images/banner/banner-12.jpg')"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
    >
        <div class="profile-image-wrapper">
            <div class="profile-image p-0">
                <b-avatar size="114" variant="light-primary" :src="channelData.picture" />
            </div>
        </div>
        <h3>{{ channelData.name }}</h3>
        <h6 class="text-muted">
            <b-link :href="`https://www.youtube.com/channel/${channelData.channelId}`" target="_blank">
                {{ channelData.channelId }}
            </b-link>
        </h6>
        <b-badge v-if="channelData.isClosed" class="profile-badge" variant="light-warning"> Closed </b-badge>
        <b-badge v-else class="profile-badge" variant="light-primary"> Active </b-badge>
        <hr class="mb-2" />

        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h6 class="text-muted font-weight-bolder">Videos</h6>
                <h3 class="mb-0">
                    {{ channelData.videoCount }}
                </h3>
            </div>
            <div>
                <h6 class="text-muted font-weight-bolder">Subscribers</h6>
                <h3 class="mb-0">
                    {{ channelData.subscriberCount }}
                </h3>
            </div>
            <div>
                <h6 class="text-muted font-weight-bolder">Views</h6>
                <h3 class="mb-0">
                    {{ channelData.viewCount }}
                </h3>
            </div>
        </div>
        <!--/ follower projects rank -->
    </b-card>
</template>

<script>
import { BCard, BAvatar, BBadge, BLink } from "bootstrap-vue";

export default {
    components: {
        BCard,
        BBadge,
        BAvatar,
        BLink,
    },
    props: {
        channelData: {
            type: Object,
            required: true,
        },
    },
};
</script>
