<template>
    <b-card :img-src="videoData.thumbnail" img-top class="card-profile">
        <h3 class="mt-n3">
            {{ videoData.name }}
        </h3>
        <h6 class="text-muted">
            <b-link :href="`https://www.youtube.com/video/${videoData.videoId}`" target="_blank">
                {{ videoData.videoId }}
            </b-link>
        </h6>
        <b-badge v-if="videoData.isDeleted" class="profile-badge" variant="light-warning"> Deleted </b-badge>
        <b-badge v-else class="profile-badge" variant="light-primary"> Active </b-badge>
        <hr class="mb-2" />

        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h6 class="text-muted font-weight-bolder">Views</h6>
                <h3 class="mb-0">
                    {{ videoData.viewCount }}
                </h3>
            </div>
            <div>
                <h6 class="text-muted font-weight-bolder">Likes</h6>
                <h3 class="mb-0">
                    {{ videoData.likeCount }}
                </h3>
            </div>
            <div>
                <h6 class="text-muted font-weight-bolder">Comments</h6>
                <h3 class="mb-0">
                    {{ videoData.commentCount }}
                </h3>
            </div>
        </div>
        <!--/ follower projects rank -->
    </b-card>
</template>

<script>
import { BCard, BBadge, BLink } from "bootstrap-vue";

export default {
    components: {
        BCard,
        BBadge,
        BLink,
    },
    props: {
        videoData: {
            type: Object,
            required: true,
        },
    },
};
</script>
