<template>
    <div>
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="channelData === undefined">
            <h4 class="alert-heading">Error fetching user data</h4>
            <div class="alert-body">
                No user found with this user id. Check
                <b-link class="alert-link" :to="{ name: 'marketing-channel-list' }"> User List </b-link>
                for other users.
            </div>
        </b-alert>

        <template v-if="channelData">
            <!-- First Row -->
            <b-row>
                <b-col cols="12" xl="3" lg="3" md="3">
                    <channel-view-user-info-card :channel-data="channelData" />
                </b-col>
                <b-col v-for="video in channelData.videos" :key="video.id" cols="12" xl="3">
                    <channel-video-card :video-data="video" />
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import store from "@/store";
import router from "@/router";
import channelStoreModule from "../channelStoreModule";
import ChannelViewUserInfoCard from "./ChannelViewUserInfoCard.vue";
import ChannelVideoCard from "./ChannelVideoCard.vue";

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,

        // Local Components
        ChannelViewUserInfoCard,
        ChannelVideoCard,
    },
    setup() {
        const channelData = ref(null);

        const USER_APP_STORE_MODULE_NAME = "app-user";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
            store.registerModule(USER_APP_STORE_MODULE_NAME, channelStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            }
        });

        store
            .dispatch("app-user/fetchChannel", {
                id: router.currentRoute.params.id,
            })
            .then(response => {
                channelData.value = response.data;
            })
            .catch(error => {
                if (error.response.status === 404) {
                    channelData.value = undefined;
                }
            });

        return {
            channelData,
        };
    },
};
</script>

<style></style>
